export const state = () => ({
  isFormActive: false,
  isFormImported: false,
});

export const getters = {
  getCounter(state) {
    return state.isFormActive;
  },
};

export const mutations = {
  toggle(state) {
    state.isFormActive = !state.isFormActive;
  },
  setFormImported(state) {
    state.isFormImported = true;
  },
};
