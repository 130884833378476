//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    color: {
      type: String,
      default: "#FD6C43",
    },

    opacity: {
      type: Number,
      default: 1,
    },
  },
};
