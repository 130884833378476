var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(_vm.componentType,{tag:"component",staticClass:"btn-link",attrs:{"to":{ name: _vm.link }},on:{"click":_vm.activeForm}},[_c('div',{staticClass:"btn-ncy p-5 rounded-lg font-cocogoose-darkmode relative mx-auto text-sm md:text-base leading-[120%] cursor-pointer transition-all duration-300 flex items-center",class:[
        _vm.invert ? 'bg-whitency text-orangency' : 'bg-orangency text-whitency',
        _vm.iconName ? 'gap-x-4' : 'hover:gap-x-2 pr-0',
        !_vm.arrow && 'pr-6',
        _vm.className,
        _vm.color,
        _vm.background,
        _vm.padding ]},[(_vm.iconName)?_c(_vm.iconName,{tag:"component"}):_vm._e(),_vm._v(" "),(_vm.isText)?_c('div',{staticClass:"wrapper-text"},[_c('div',{staticClass:"btn-text",attrs:{"data-before":_vm.text}},[_vm._v(_vm._s(_vm.text))])]):_vm._e(),_vm._v(" "),(_vm.down)?[(!_vm.iconName && _vm.arrow)?_c('IconsArrowDownwardRounded',{staticClass:"btn-icon-arrow"}):_vm._e()]:[(!_vm.iconName && _vm.arrow)?_c('IconsArrowForwardRounded',{staticClass:"btn-icon-arrow"}):_vm._e()]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }