//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Articles from "~/assets/blog.json";

export default {
  data() {
    return {
      Articles,
      path: this.$route.path,
    };
  },
  computed: {
    myArticle() {
      let article = "";
      this.Articles.forEach((x) =>
        "/blog/" + x.url === this.path ? (article = x) : null
      );
      return article;
    },

    previousArticle() {
      let previous = "";
      const dates = [];
      let index = "";
      this.Articles.forEach((x) => dates.push(Date.parse(x.date)));
      dates.forEach((x) =>
        x === Date.parse(this.myArticle.date)
          ? (index = dates.indexOf(x))
          : null
      );
      index < 0
        ? (previous = null)
        : (previous = this.Articles[index + 1]?.url);

      return previous;
    },

    nextArticle() {
      let next = "";
      const dates = [];
      let i = "";
      this.Articles.forEach((x) => dates.push(Date.parse(x.date)));
      dates.forEach((x) =>
        x === Date.parse(this.myArticle.date) ? (i = dates.indexOf(x)) : null
      );
      i > this.Articles.length
        ? (next = null)
        : (next = this.Articles[i - 1]?.url);

      return next;
    },
  },
};
