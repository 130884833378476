//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gsap } from "gsap";

export default {
  props: {
    isPhoneActive: {
      type: Boolean,
      default: true,
    },

    invertLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      services: [
        {
          label: "Graphisme",
          link: "graphisme",
        },
        {
          label: "Site web",
          link: "web",
        },
        {
          label: "Marketing",
          link: "strategy",
        },
        {
          label: "SEO",
          link: "referencement",
        },
        {
          label: "Motion Design",
          link: "motion",
        },
        {
          label: "Social Media",
          link: "social",
        },
        {
          label: "Vidéo",
          link: "video",
        },
      ],
      tlOpen: null,
      tlClose: null,
    };
  },

  computed: {
    isCaseStudy() {
      return /etudes-de-cas\/\S+/gm.test(this.$route.path);
    },
  },
  mounted() {
    document.body.classList.remove("has-menu-active");
    document.body.style.height = "unset";
    document.body.style.overflow = "unset";
  },

  beforeDestroy() {
    this.tlOpen?.kill();
    this.tlClose?.kill();
  },

  methods: {
    isDesktop() {
      return window.matchMedia("(min-width: 1024px)").matches;
    },
    toggleMenu() {
      this.isActive = !this.isActive;

      if (this.isActive) {
        document.body.style.height = "100vh";
        if (this.isDesktop()) {
          document.body.classList.add("has-menu-active");
        }
        document.body.style.overflow = "hidden";
        this.tlOpen = gsap
          .timeline()
          .to(".menu-container", {
            y: 0,
          })
          .to(
            ".menu a",
            {
              y: 0,
              stagger: 0.03,
            },
            "<-0.2"
          )
          .to(
            ".info-menu",
            {
              opacity: 1,
            },
            ">+0.2"
          );
        if (this.isDesktop()) {
          this.tlOpen.to(
            ".big-n",
            {
              filter: "drop-shadow(0 0 58.906px #f05225)",
              duration: 2,
              ease: "none",
            },
            "<-0.1"
          );
        }
      } else {
        document.body.style.height = "unset";
        document.body.style.overflowY = "unset";
        document.body.classList.remove("has-menu-active");
        this.tlClose = gsap
          .timeline()
          .set(".menu a", {
            y: "100%",
          })
          .set(".info-menu", {
            opacity: 0,
          })
          .to(".menu-container", {
            y: "100%",
          });
        if (this.isDesktop()) {
          this.tlClose.set(".big-n", {
            filter: "drop-shadow(0 0 0px #f05225)",
          });
        }
      }
    },
  },
};
