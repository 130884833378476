import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=68e5cc49&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"
import style0 from "./Button.vue?vue&type=style&index=0&id=68e5cc49&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68e5cc49",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsArrowDownwardRounded: require('/app/components/Icons/ArrowDownwardRounded.vue').default,IconsArrowForwardRounded: require('/app/components/Icons/ArrowForwardRounded.vue').default})
