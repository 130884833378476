//
//
//

import Lenis from "@studio-freight/lenis";

export default {
  data() {
    return {
      lenis: null,
      requestId: null,
    };
  },

  mounted() {
    this.lenis = new Lenis();
    this.requestId = requestAnimationFrame(this.raf);
  },

  beforeDestroy() {
    cancelAnimationFrame(this.requestId);
    this.lenis.destroy();
  },

  methods: {
    raf(time) {
      this.lenis.raf(time);
      this.requestId = requestAnimationFrame(this.raf);
    },
  },
};
