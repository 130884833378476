export const state = () => ({
  isNewsletterActive: true,
  isTypeformImported: false,
});

export const getters = {
  getCounter(state) {
    return state.isNewsletter;
  },
};

export const mutations = {
  toggle(state) {
    state.isNewsletterActive = false;
  },
  activeTypeform(state) {
    state.isTypeformImported = true;
  },
};
