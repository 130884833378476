import Vue from "vue";
import {
  Swiper as SwiperClass,
  Pagination,
  Autoplay,
  Navigation,
  FreeMode,
} from "swiper";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

// import style
import "swiper/swiper-bundle.min.css";

SwiperClass.use([Pagination, Autoplay, Navigation, FreeMode]);
Vue.use(getAwesomeSwiper(SwiperClass));
