//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: String,
      default: "Contactez-nous",
    },
    link: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "",
    },
    invert: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: null,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: String,
      default: "",
    },
    isText: {
      type: Boolean,
      default: true,
    },
    down: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    componentType() {
      return this.link ? "nuxt-link" : "div";
    },
  },

  methods: {
    activeForm() {
      if (this.isForm) {
        this.$store.commit("form/toggle");
      }
    },
  },
};
