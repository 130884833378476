//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isTrait: {
      type: Boolean,
      default: true,
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
};
